import React, { useEffect, useRef, useState } from "react";
import Button from "../../../components/Button";
import DataTable from "../../../components/DataTable";
import { Container } from "./styles";
import api from "../../../services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colors } from "../../../constants/colors";
import { useNavigate } from "react-router-dom";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Section } from "../../../components/Section/styles";
import Spinner from "../../../components/Spinner";
import InputButton from "../../../components/IconButton";
import moment from 'moment';
import InputSearch from "../../../components/InputSearch";
import Progress from "../../../components/Progress";

const statusList = {
  pending: {
    class: 'alert-warning',
    title: 'Pendente'
  },
  processing: {
    class: 'alert-info',
    title: 'Em progresso'
  },
  completed: {
    class: 'alert-success',
    title: 'Concluído'
  },
  canceled: {
    class: 'alert-danger',
    title: 'Cancelado'
  },
}

const Imports = () => {

  const [ page, setPage ] = useState(1);
  const [ queues, setQueues ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ exportAllLoading, setExportAllLoading ] = useState(false);
  const [ deleteSelectedLoading, setDeletedSdeleteSelectedLoading ] = useState(false);
  const [ columns, setColumns ] = useState([]);
  const [ lastUpdate, setLastUpdate ] = useState();
  const [ search, setSearch ] = useState('');

  const navigate = useNavigate();

  useEffect(() =>{
    setColumns([
      { title: 'ID', key: 'id'},
      { title: 'Nome da Lista', key: 'name'},
      { title: 'Quantidade de CNPJs', key: 'total', render: (item) => (<>
        <span>{item.total} CNPJs</span>
      </>)},
      { title: 'Status', key: 'status', render: (item) => (<>
        <span class={`badge ${statusList[item.status].class}`} >{statusList[item.status].title}</span>
      </>)},
      { title: 'Progresso', key: 'completed', render: (item) => {
        const percent = Math.ceil(parseInt(item.completed)/parseInt(item.total) * 100);
        return <Progress percent={percent} total={item.total} completed={item.completed}/>}},
      // { title: 'Distância', key: 'distance', sortKey: 'Stablishment.distance'},
      // { title: 'Criado em', key: 'createdAt', sortKey: 'Stablishment.createdAt', render: (item) => moment(item.createdAt).format('DD/MM/YYYY HH:mm')},
      // { title: 'Status', key: 'status', sortKey: 'Stablishment.status'},
    ]);

    // fetchUpdated();
    
  }, []);

  const searchDebounce = useRef();

  useEffect(() => {
    
    if(searchDebounce.current) clearTimeout(searchDebounce.current);
    searchDebounce.current = setTimeout(() =>{
      fetchData();
    }, 300);

  }, [ search ]);

  const fetchUpdated = async () => {
    const { data } = await api.get('stablishments/last-update');
    setLastUpdate(data.lastUpdate);
  }

  const fetchData = async (sortBy = null) => {
    setLoading(true);
    const sortObj = sortBy ? { sort: sortBy } : {};
    try{
      setPage(1);
      const { data } = await api.get('filas', {
        // params:{
        //   userid: 22,
        //   search,
        //   ...sortObj
        // }
      });

      console.log(data)

      setQueues(data);
    }catch(err){
      console.log(err);
    }
    setLoading(false);
  }
  
  // const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  // const fileExtension = '.xlsx';

  const getProducts = async (id) => {
    try{
      const { data } = await api.get(`products/export?id=${id}`);

      return data;
    }catch(err){
      console.log(err);
    }
    return [];
  }

  const getAllProducts = async () => {
    try{
      const { data } = await api.get(`products/all`);

      return data;
    }catch(err){
      console.log(err);
    }
    return [];
  }

  

  const exportToCSV = async (item) => {

    setQueues(prev => {
      const up = [ ...prev ];
      up.find(p => p.stablishmentId === item.stablishmentId).loadingExport = true;
      return up;
    })

    try{
      const products = await getProducts(item.stablishmentId);
  
      if(!products.length){
        window.alert('Nenhum produto nessa farmácia');
        throw new Error('Nenhum produto nessa farmácia');
      }

      let csvData = products.map(obj => '"' + Object.values(obj).join('";"') + '"');
      csvData.unshift(Object.keys(products[0]).join(';'));
      const blob = new Blob([`\uFEFF${csvData.join('\n')}`], { type: 'text/csv;charset=utf-8;' });
      const csvURL = URL.createObjectURL(blob);
    
  
      // const ws = XLSX.utils.json_to_sheet(products);
      // const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      // const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array'  });
      // const data = new Blob([excelBuffer], {type: fileType});
      FileSaver.saveAs(csvURL, item.stablishmentName + '.csv');
    }catch(e){
      console.log(e);
    }

    setQueues(prev => {
      const up = [ ...prev ];
      up.find(p => p.stablishmentId === item.stablishmentId).loadingExport = false;
      return up;
    })
  }

  const exportAll = async () => {
    setExportAllLoading(true);
    const products = await getProducts((queues.some(p => p?.selected) ? queues.filter(p => p?.selected) : queues).map((p) => p.stablishmentId).join(','));

    if(!products.length){
      window.alert('Nenhum ponto de coleta');
      setExportAllLoading(false);
      return;
    }

    let csvData = products.map(obj => '"' + Object.values(obj).join('";"') + '"');
    csvData.unshift(Object.keys(products[0]).join(';'));
    const blob = new Blob([`\uFEFF${csvData.join('\n')}`], { type: 'text/csv;charset=utf-8;' });
    const csvURL = URL.createObjectURL(blob);
    FileSaver.saveAs(csvURL, 'Pontos de coleta.csv');
    setExportAllLoading(false);

  }

  const deleteStablishment = async (id) => {
    try{
      if(window.confirm('Deseja apagar essa farmácia?') == true){
        await api.delete('/stablishments', {
          params:{
            id
          }
        });
        fetchData();
      }
    }catch(e){
      console.log(e);
    }
  }

  const selectAll = () => {
    setQueues(prev => {
      if(prev.every(p => p.selected)){
        return prev.map(p => ({...p, selected: false}));
      }else{
        return prev.map(p => ({...p, selected: true}));
      }
    })
  }

  const deleteSelected = async () => {
    const selected = queues.filter(p => p.selected).map(p => p.stablishmentId).join(',')
    
    if(!selected){
       return
    }

    await deleteStablishment(selected);
  }

  const handleSelection = (stablishmentId, checked) => {
    setQueues(prev => {
      const up = [...prev];
      up.find(p => p.stablishmentId === stablishmentId).selected = checked
      return up;
    });
  }

  const renderSelections = (item) => {
    return <input type="checkbox" checked={item?.selected} onChange={(e)=>handleSelection(item.stablishmentId, e.target.checked)} />
  }

  return <Container>
    <div className="d-flex align-items-center justify-content-between flex-wrap gap-3">
      <h5 className="section-title mb-0" style={{fontWeight: 700}}>Lista de Importações</h5>
        <Button className={'btn-neutral'} style={{color: 'white', background: colors.success, borderRadius: 10, fontSize: 14, fontWeight: 600}} onClick={()=>navigate('/importacoes/novo')}>
          <FontAwesomeIcon icon={'plus'} /> NOVA IMPORTAÇÃO
        </Button>
    </div>

    <Section className="mt-3">
      {/* <InputSearch
        search={search}
        onChange={setSearch}
        onSubmitSearch={() => fetchData(null)}
      /> */}
      {loading ?
      <Spinner/>
      :
      (queues.length ?
        <DataTable
          className="mt-3"
          columns={columns}
          setColumns={setColumns}
          data={queues}
          onSubmitSearch={(v) => fetchData()}
          // topActions={[
          //   <Button loadingText={'Aguarde! Exportando...'} className={'btn-primary'} onClick={()=>selectAll()}>
          //     { 
          //       'Selecionar todos' 
          //     }
          //   </Button>,
          //   <Button loadingText={'Aguarde! Exportando...'} className={'btn-primary'} onClick={()=>exportAll()}>
          //     { 
          //       queues.some(p => p.selected) ? 'Exportar ' + queues.filter(p => p.selected).length + ' selecionados' : 'Exportar todos' 
          //     }
          //   </Button>,
          //   queues.some(p => p.selected) && <Button loading={deleteSelectedLoading} style={{color: 'red'}} loadingText={'Aguarde! Excluindo...'} className={'btn-outline'} onClick={()=>deleteSelected()}>
          //     { 
          //       'Excluir ' + queues.filter(p => p.selected).length + ' selecionados'
          //     }
          //   </Button>,
          // ]}
          // onSort={fetchData}
          page={page}
          onClickRow={(item) => {
            navigate('/importacoes/' + item.id);
          }}
          onChangePage={setPage}
          actions={(item) =>
            <span className="d-flex gap-1 align-items-center">
              {/* <Button loading={item.loadingExport} onClick={()=>exportToCSV(item)}>
                <FontAwesomeIcon icon={'download'} color={colors.primaryColor} />
              </Button> */}
              <div class="btn-group">
                <a href="#" className="text-dark" data-bs-toggle="dropdown" aria-expanded="false"><FontAwesomeIcon icon={'ellipsis-vertical'} /></a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li><button class="dropdown-item btn-sm" type="button"><FontAwesomeIcon icon="edit" color={colors.primaryColor}/> Editar</button></li>
                  <li><button class="dropdown-item btn-sm" type="button"><FontAwesomeIcon icon="cancel" color="red"/> Cancelar</button></li>
                  <li><button class="dropdown-item btn-sm" type="button"><FontAwesomeIcon icon="trash-alt" color="red"/> Excluir</button></li>
                </ul>
              </div>
              
            </span>
          }
        />
      :
      <div className="text-center">
        <FontAwesomeIcon icon={'cloud-arrow-up'} fontSize={34} color={colors.muted} />
        <h6 className="mt-3"><b>Nenhuma importação</b></h6>
      </div>
      )
      }
    </Section>


  </Container>
}

export default Imports;